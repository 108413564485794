export default class PilgrimsList {

    constructor(pilgrims) {
        this.pilgrims = pilgrims
        this.sort()
    }

    get list() {
        return this.pilgrims
    }

    get length() {
        return this.pilgrims.length
    }

    sort() {
        this.pilgrims.sort((p1, p2) => {
            if (p1.fio > p2.fio) {
                return 1;
              }
              if (p1.fio < p2.fio) {
                return -1;
              }
              // a должно быть равным b
              return 0;
        }) 
    }

    addPilgrim(pilgrim) {

        if (this.pilgrims.filter((p) => {
            return p.ID === pilgrim.ID
        }).length > 0) return

        this.pilgrims.push(pilgrim)
        this.sort()
    }

    removePilgrim(pilgrimID) {
        this.pilgrims = this.pilgrims.filter((pilgrim) => {
            return pilgrim.ID !== pilgrimID
        })
        this.sort()
    }
}