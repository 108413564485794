<template>
    <div class="container-fluid">
        <h1>Панель готовности заселения в Мина</h1>
        <table class="table table-sm table-striped table-bordered">
            <thead class="sticky-top bg-light">
                <th style="width: 40px" class="text-center">#</th>
                <th>Рейс</th>
                <th>Группа</th>
                <th>
                    Палатки (Места - {{seats("m")}}) 
                    <span class="badge bg-success">+{{ extraSeets("m") }}</span>
                    <span class="badge bg-danger ms-1">-{{ seatsNeed("m") }}</span>
                </th>
                <th>Кол. ({{pilgrims("m")}})</th>
                <th>Заселено ({{completeQuantity("m")}})</th>
                <th>
                    Палатки (Места - {{seats("f")}}) 
                    <span class="badge bg-success">+{{ extraSeets("f") }}</span>
                    <span class="badge bg-danger ms-1">-{{ seatsNeed("f") }}</span>
                </th>
                <th>Кол. ({{pilgrims("f")}})</th>
                <th>Заселено ({{completeQuantity("f")}})</th>
            </thead>
            <tbody>
                <template v-for="(flight, i) in flights">
                    <tr v-for="(group, j) in flight.groups">
                        <td v-if="j == 0" :rowspan="flight.groups.length" class="text-center align-middle">
                            {{ i + 1 }}
                        </td>
                        <th v-if="j == 0" :rowspan="flight.groups.length" class="text-center align-middle"
                            :class="{ 'bg-success text-white': flight.isComplete }" style="--bs-bg-opacity: 0.7">
                            {{ flight.number }}
                        </th>
                        <td>{{ group.number }}</td>
                        <td :title="group.m.roomsDesc" @click="copyRoomsDesc(group.m)" class="bg-info" style="--bs-bg-opacity: 0.2">
                            {{ group.m.rooms }} ({{ group.m.seats }})
                            <span v-if="group.m.seats - group.m.pilgrims != 0" class="badge"
                                :class="{ 'bg-success': group.m.seats - group.m.pilgrims > 0, 'bg-danger': group.m.seats - group.m.pilgrims < 0 }">{{
                                        group.m.seats - group.m.pilgrims
                                }}</span>
                        </td>
                        <td class="bg-info" style="--bs-bg-opacity: 0.2"> {{ group.m.pilgrims }} </td>
                        <td :class="{ 'bg-success text-white': group.m.isComplete }" style="--bs-bg-opacity: 0.7">
                            {{ group.m.completeQuantity }}
                        </td>
                        <td :title="group.f.roomsDesc" @click="copyRoomsDesc(group.f)" class="bg-danger" style="--bs-bg-opacity: 0.2">
                            {{ group.f.rooms }} ({{ group.f.seats }})
                            <span v-if="group.f.seats - group.f.pilgrims != 0" class="badge"
                                :class="{ 'bg-success': group.f.seats - group.f.pilgrims > 0, 'bg-danger': group.f.seats - group.f.pilgrims < 0 }">{{
                                        group.f.seats - group.f.pilgrims
                                }}</span>
                        </td>
                        <td class="bg-danger" style="--bs-bg-opacity: 0.2"> {{ group.f.pilgrims }} </td>
                        <td :class="{ 'bg-success text-white': group.f.isComplete }" style="--bs-bg-opacity: 0.7">
                            {{ group.f.completeQuantity }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>

import axios from 'axios'

class Flight {
    constructor(flight) {
        this.flight = flight
    }

    get number() {
        return this.flight.flight
    }

    get groups() {
        return this.flight.groups
    }

    get isComplete() {
        return this.flight.groups.reduce((fIsComplete, group) => fIsComplete && group.m.isComplete && group.f.isComplete, true)
    }
}

export default {
    name: "DashboardView",
    data: () => {
        return {
            flights: []
        }
    },
    created() {
        axios.get("https://settlement.torgvisor.ru/?r=site/dashboard")
            .then(r => r.data)
            .then(data => {
                this.flights = this.prepareFlights(data).map(f => new Flight(f))
            })
    },
    methods: {
        seatsNeed(gender) {
            return this.flights.reduce((q, flight) => {
                return flight.groups.reduce((q, group) => {
                    return q + Math.max(group[gender].pilgrims - group[gender].seats, 0)
                }, q)
            }, 0)
        },
        extraSeets(gender) {
            return this.flights.reduce((q, flight) => {
                return flight.groups.reduce((q, group) => {
                    return q + Math.max(group[gender].seats - group[gender].pilgrims, 0)
                }, q)
            }, 0)
        },
        pilgrims(gender) {
            return this.flights.reduce((q, flight) => {
                return flight.groups.reduce((q, group) => {
                    return q + group[gender].pilgrims
                }, q)
            }, 0)
        },
        seats(gender) {
            return this.flights.reduce((q, flight) => {
                return flight.groups.reduce((q, group) => {
                    return q + group[gender].seats
                }, q)
            }, 0)
        },
        completeQuantity(gender) {
            return this.flights.reduce((q, flight) => {
                return flight.groups.reduce((q, group) => {
                    return q + group[gender].completeQuantity
                }, q)
            }, 0)
        },
        copyRoomsDesc(group){
            navigator.clipboard.writeText(group.roomsDesc)
        },
        prepareFlights(flights) {
            return flights.map((flight) => {
                let newGroups = []
                let d = flight.groups.length / 2

                for(let i=0; i<flight.groups.length/2; i++) {
                    newGroups.push({
                        'number': flight.groups[i].number,
                        'm': flight.groups[i],
                        'f': flight.groups[i+d]
                    })
                }

                return {
                    flight: flight.flight,
                    groups: newGroups
                }
            })
        }
    }
}
</script>