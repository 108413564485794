import axios from "axios"

export default class PilgrimsRepository {

    constructor(pilgrims) {
        this.pilgrims = pilgrims
    }

    static assemble(flight, group, callback) {
        axios.get(`https://settlement.torgvisor.ru/?r=site/pilgrims-list&flight=${flight}&group=${group}`)
            .then(r => r.data)
            .then(pilgrims => {
                callback(new PilgrimsRepository(pilgrims))
            })
    }

    updatePilgrim(pilgrim) {
        let index = this.pilgrims.findIndex((p) => {
            return p.ID === pilgrim.ID
        })

        if (index == -1) return
        console.log(pilgrim)
        this.pilgrims[index] = pilgrim
    }

    syncToServer() {

        axios.post(`https://settlement.torgvisor.ru/?r=site/update-pilgrims`, this.pilgrims).then((response) => {
            console.log(response.status)
        })
    }
}