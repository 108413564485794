<template>
  <div class="admin">
    <h1>This is an admin page</h1>
  </div>
</template>
<script>
export default {
  name: 'AdminView',
  data: () => {
    return {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>