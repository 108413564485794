import axios from "axios"

export default class FlightSettlenet {
    
    constructor(flight, group, rooms, pilgrimRepository) {
        this.flight = flight
        this.group = group
        this.pilgrimRepository = pilgrimRepository
        this.pilgrims = []
        this.rooms = rooms
        this.roomsIDs = rooms.map(r => r.number)

        this.reloadPilgrims()
    }

    static assemble(flight, group, pilgrimRepository, callback) {

        axios.get(`https://settlement.torgvisor.ru/?r=site/rooms-list&flight=${flight}&group=${group}`)
            .then(r => r.data)
            .then(rooms => {
                rooms = rooms.map((room) => {
                    room.capacity = parseInt(room.capacity)
                    return room
                })
                callback(new FlightSettlenet(flight, group, rooms, pilgrimRepository))
            })
    }

    reloadPilgrims() {
        this.pilgrims = this.pilgrimRepository.pilgrims.filter((p) => {
            return p.reys === this.flight && p.grp === this.group
        })
    }

    getNotSettlementPilgrims(gender) {

        let pGender = gender === 1 ? "м" : "ж"

        return this.pilgrims.filter((p) => {
            return !this.roomsIDs.includes(p.room) && p.gender === pGender
        })
    }

    getPilgrimsForRoom(room) {
        return this.pilgrims.filter((p) => {
            return p.room === room
        })
    }

    getMenRooms() {
        return this.rooms.filter((room) => {
            return room.gender === 1
        })
    }

    getWomenRooms() {
        return this.rooms.filter((room) => {
            return room.gender === 2
        })
    }
}