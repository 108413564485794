export default class Room {

    constructor(info, pilgrims) {
        this.info = info
        this.pilgrims = pilgrims
        this.pilgrims.sort((p1, p2) => {
            if (p1.fio > p2.fio) {
                return 1;
              }
              if (p1.fio < p2.fio) {
                return -1;
              }
              // a должно быть равным b
              return 0;
        }) 
    }

    get number() {
        return this.info.number
    }
    
    get capacity() {
        return this.info.capacity
    }

    get size() {
        return this.pilgrims.length
    }
}