<template>
    <div class="container-fluid" v-if="flightSettlement !== undefined">
        <h1>Рейс: {{ flight }}. Группа: {{ group }}. Количество паломников: {{ flightSettlement.pilgrims.length }}</h1>
        <hr />
        <h3 class="text-info">Мужские палатки. <i class="text-secondary">Осталось заселить:</i>
            {{ this.flightSettlement.getNotSettlementPilgrims(1).length }} <i class="text-success">Свободных мест:</i>
            {{ freeMenBedQuantity }}</h3>
        <div v-for="rooms in menRooms" class="row mb-2">
            <div v-for="room in rooms" class="col-md-4">
                <div class="card mb-2">
                    <div class="card-header d-flex justify-content-between align-items-center bg-info text-light">
                        <span>
                            Палатка: <span class="badge bg-danger">{{ room.number }}</span> Кол.: <span
                                class="badge bg-danger">{{ room.size }} из {{ room.capacity }}</span>
                        </span>
                        <button class="btn btn-success btn-sm"
                            @click="showSettlementForRoom(room.info)">Заселить</button>
                    </div>
                    <div class="card-body" v-if="flightSettlement.getPilgrimsForRoom(room.number).length === 0">
                        Пусто
                    </div>
                    <table class="table table-sm table-bordered table-striped" v-else>
                        <tbody>
                            <tr v-for="(pilgrim, i) in room.pilgrims">
                                <th class="text-center">{{ i + 1 }}</th>
                                <td>{{ pilgrim.fio }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <hr />

        <h3 class="text-danger">Женские палатки. <i class="text-secondary">Осталось заселить:</i>
            {{ this.flightSettlement.getNotSettlementPilgrims(2).length }} <i class="text-success">Свободных мест:</i>
            {{ freeWomenBedQuantity }}</h3>
        <div v-for="rooms in womenRooms" class="row mb-2">
            <div v-for="room in rooms" class="col-md-4">
                <div class="card mb-2">
                    <div class="bg-danger card-header d-flex justify-content-between align-items-center text-light">
                        <span>
                            Палатка: <span class="badge bg-info">{{ room.number }}</span> Кол.: <span
                                class="badge bg-info">{{ room.size }} из {{ room.capacity }}</span>
                        </span>
                        <button class="btn btn-success btn-sm"
                            @click="showSettlementForRoom(room.info)">Заселить</button>
                    </div>
                    <div class="card-body" v-if="flightSettlement.getPilgrimsForRoom(room.number).length === 0">
                        Пусто
                    </div>
                    <table class="table table-sm table-bordered table-striped" v-else>
                        <tbody>
                            <tr v-for="(pilgrim, i) in room.pilgrims">
                                <th class="text-center">{{ i + 1 }}</th>
                                <td>{{ pilgrim.fio }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="conteiner room-settlement" v-if="settlement !== null">
            <div class="room-settlement__header ps-3 pe-3">
                <h1 class="mt-2 d-inline-block">Палатка: {{ settlement.room.number }} ({{ settlement.room.gender === 1 ?
                        "Мужской" :
                        "Женский"
                }})</h1>
                <div>
                    <button class="btn btn-secondary me-2" @click="closeSettlement">Отменить</button>
                    <button class="btn btn-info" @click="saveSettlement">Сохранить</button>
                </div>
                <h4 class="mt-2">Cвободно: {{ settlement.room.capacity - settlement.roomPilgrims.length }} из
                    {{ settlement.room.capacity }}</h4>
            </div>
            <div class="room-settlement__body ps-3 pe-3">
                <table class="table table-bordered table-sm">
                    <tbody>
                        <tr v-for="(p, i) in settlement.roomPilgrims.list">
                            <td style="width: 50px" class="text-center">
                                <button class="btn btn-sm btn-outline-danger"
                                    @click="evictPilgrimFromRoom(p)">-</button>
                            </td>
                            <th scope="row" style="width: 50px" class="text-center">{{ i + 1 }}</th>
                            <td>{{ p.fio }}</td>
                        </tr>
                    </tbody>
                </table>

                <h3>Не размещены</h3>
                <table class="table table-bordered table-sm">
                    <tbody>
                        <tr v-for="(p, i) in settlement.notSettlementPilgrims.list">
                            <td style="width: 50px" class="text-center">
                                <button class="btn btn-sm btn-outline-success"
                                    @click="settlePilgrimToRoom(p)">+</button>
                            </td>
                            <th scope="row" style="width: 50px" class="text-center">{{ i + 1 }}</th>
                            <td>{{ p.fio }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="loader" v-if="isLoading">
        Грузится. Подождите
    </div>
</template>
<script>

import FlightSettlement from "../models/FlightSettlement.js"
import PilgrimsList from "../models/PilgrimsList.js"
import Room from "../models/Room.js"
import PilgrimsRepository from "../models/PilgrimsRepository.js"

export default {
    name: 'FlightView',
    data: () => {
        return {
            isLoading: false,
            pilgrimsRepository: undefined,
            flightSettlement: undefined,
            settlement: null,
            flight: "",
            group: "",

        }
    },
    computed: {
        freeMenBedQuantity() {
            return this.menRooms.flatMap(i => i).reduce((q, room) => {
                return q + room.capacity - room.size
            }, 0)
        },
        menRooms: function () {
            let menRooms = this.flightSettlement.getMenRooms()

            return Array.from({ length: 3 }, () => menRooms.splice(0, 3).map((info) => {
                return new Room(info, this.flightSettlement.getPilgrimsForRoom(info.number))
            }))
        },
        freeWomenBedQuantity() {
            return this.womenRooms.flatMap(i => i).reduce((q, room) => {
                return q + room.capacity - room.size
            }, 0)
        },
        womenRooms: function () {
            let womenRooms = this.flightSettlement.getWomenRooms()

            return Array.from({ length: 3 }, () => womenRooms.splice(0, 3).map((info) => {
                return new Room(info, this.flightSettlement.getPilgrimsForRoom(info.number))
            }))
        }
    },
    created() {

        let hash = window.location.hash.substring(1)

        this.flight = hash.substring(0, hash.length - 2)
        this.group = hash.substring(hash.length - 1)

        window.addEventListener("hashchange", (event) => {
            window.location.reload()
        })
    },
    mounted() {

        this.isLoading = true
        PilgrimsRepository.assemble(this.flight, this.group, (repository) => {
            this.pilgrimsRepository = repository

            FlightSettlement.assemble(this.flight, this.group, repository, (flightSettlement) => {
                this.flightSettlement = flightSettlement
                this.isLoading = false
            })
        })
    },
    methods: {
        showSettlementForRoom(room) {
            this.settlement = {
                room: room,
                roomPilgrims: new PilgrimsList(this.flightSettlement.getPilgrimsForRoom(room.number)),
                notSettlementPilgrims: new PilgrimsList(this.flightSettlement.getNotSettlementPilgrims(room.gender))
            }
        },
        settlePilgrimToRoom(pilgrim) {
            if (this.settlement.room.capacity - this.settlement.roomPilgrims.length === 0) return

            this.settlement.roomPilgrims.addPilgrim(pilgrim)
            this.settlement.notSettlementPilgrims.removePilgrim(pilgrim.ID)
        },
        evictPilgrimFromRoom(pilgrim) {
            this.settlement.roomPilgrims.removePilgrim(pilgrim.ID)
            this.settlement.notSettlementPilgrims.addPilgrim(pilgrim)
        },
        saveSettlement() {

            for (let pilgrim of this.settlement.roomPilgrims.list) {
                pilgrim.room = this.settlement.room.number
                this.pilgrimsRepository.updatePilgrim(pilgrim)
            }

            for (let pilgrim of this.settlement.notSettlementPilgrims.list) {
                pilgrim.room = null
                this.pilgrimsRepository.updatePilgrim(pilgrim)
            }

            this.flightSettlement.reloadPilgrims()
            this.pilgrimsRepository.syncToServer()
            this.settlement = null
        },
        closeSettlement() {
            this.settlement = null
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.room-settlement {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px -5px 5px black;
    display: flex;
    flex-direction: column;

    &__header {
        
    }

    &__body {
        overflow: auto;
    }
}

.loader {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>