import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from "../views/AdminView.vue"
import FlightView from "../views/FlightView.vue"
import DashboarView from "../views/DashboardView.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/admin-sHhB8lAw9GQOYGoBSLL9ce2fAfxCJ8V2',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/flight',
    name: 'flight',
    component: FlightView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboarView
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
